import { initAnimationListener } from '../snippets/initAnimationListener'

export const restaurant = {
  props: {
    flexFlow: 'column',
    maxWidth: '100vw',
    boxSize: '100%'
  },
  state: ({ parent, context }) => {
    const route = context.utils.getActiveRoute(1)
    if (!route) return
    const data = parent.state.portfolio.data
    const item = data.filter(v => v.key === route.slice(1))[0]
    return item
  },
  VDHeader: {
    background: 'black',
    margin: '0.75% 0 0'
  },
  Flex: {
    props: {
      gap: 'C2',
      flow: 'column',
      align: 'center',
      width: '100%',
      maxWidth: 'I3_default',
      margin: 'D1 auto',
      padding: 'C E',
      '@mobileM': {
        padding: 'C',
        maxWidth: 'none'
      }
    },
    Picture: ({
      state
    }) => ({
      src: state.poster,
      flexAlign: 'center center',
      aspectRatio: 'auto',
      margin: 'auto',
      height: 'E',
      '& img': {
        margin: 'auto'
      }
    }),
    heading: {
      extend: 'Flex',
      props: {
        margin: '0 auto',
        maxWidth: 'I1',
        textAlign: 'center',
        align: 'center flex-start',
        gap: 'A',
        flow: 'column'
      },
      H3: {
        props: {
          color: 'brown',
          fontWeight: '300'
        },
        text: ({
          state
        }) => state.headline
      },
      P: {
        props: {
          color: 'beige',
          maxWidth: 'G2',
          margin: '0'
        },
        text: ({
          state
        }) => state.paragraph
      }
    },
    RestaurantNav: {
    }
  },
  PaperMenu: {
    props: { id: 'menu' },
    state: 'menu'
  },
  Gallery: {
    props: { id: 'gallery' },
    state: 'gallery',
    on: {
      init: (el) => initAnimationListener(el)
    }
  },
  LocationsSection: {
    props: { id: 'locations' }
  },
  SocialIcons: {
  },
  Footer: {
  }
}
