'use strict'

import { createAsync } from 'smbls'
import * as data from '.'

import { FastClick } from 'fastclick'
import SYMBOLSRC from '../symbols.json'

createAsync({
  extend: 'Flex',

  props: {
    theme: 'document',
    align: 'start center',
    padding: 'A',
    height: '100%'
  },

  data: {
    positions: {},
    frameStack: []
  },

  on: {
    load: () => FastClick.attach(document.body)
  }
}, {
  key: SYMBOLSRC.key,
  isDevelopment: true,
  editor: {
    liveSync: true,
    remote: true,
    async: true
  },
  router: {
    scrollNode: document.body,
    scrollToOptions: { behavior: 'instant' },
    scrollToTop: true
  }
}, data)
