export const Menu = {
  extend: [
    'Pseudo'
  ],
  props: {
    width: 'B2',
    height: 'Z2',
    position: 'relative',
    padding: 'A A1',

    ':after, &:before': {
      content: '""',
      position: 'absolute',
      top: 'Y2',
      left: '0',
      width: '100%',
      border: 'beige, solid',
      borderWidth: '1px 0 0 0',
      transition: 'transform B default',
      transformOrigin: '75% 100%'
    },

    ':before': {
      top: 'auto',
      bottom: 'Y2'
    },
    ':after': {
    },

    '.navActive': {
      ':before': {
        transform: 'translate3d(0, 50%, 1px) rotate(45deg)'
      },
      ':after': {
        transform: 'translate3d(0, 50%, 1px) rotate(-45deg)'

      }
    }
  },
  on: {
    click: (event, element, state) => {
      state.update({
        menuActive: !state.menuActive
      })
    }
  }
}
