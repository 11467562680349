export const initAnimationListener = el => {
  const rootData = el.__ref.__root.data
  const positions = rootData?.positions || {}
  const frameStack = rootData?.frameStack || []

  function animate () {
    frameStack.forEach(v => v.on.frame(v, v.state, v.context))
    window.requestAnimationFrame(animate)
  }

  // Start the animation
  window.requestAnimationFrame(animate)

  el.__ref.__root.data.positions = positions
  el.__ref.__root.data.frameStack = frameStack

  document.addEventListener('mousemove', (event) => {
    positions.mouseX = event.clientX
    positions.mouseY = event.clientY
  })
}
