export const Location = {
  extend: [
    'Flex'
  ],
  state: true,
  props: ({
    key,
    state
  }) => ({
    isActive: parseInt(key) === state.parent.parent?.activeMarker,
    flow: 'column',
    align: 'flex-start flex-start',
    gap: 'X',
    color: 'beige',
    minWidth: 'G2',
    '@tabletS': { fontSize: 'Z2', gap: 'V' },
    '.active': {
      background: 'brown'
    },
    childProps: {
      align: 'center flex-start',
      gap: 'X2'
    }
  }),
  Strong: {
    margin: '- - W',
    color: 'brown',
    text: ({
      state
    }) => state.address
  },
  workHours: {
    extend: 'Flex',
    Icon: {
      name: 'clock'
    },
    text: ({
      state
    }) => state.workStart + '-' + state.workEnd
  },
  phone: {
    extend: [
      'Flex',
      'Link'
    ],
    Icon: {
      name: 'phone'
    },
    props: ({
      state
    }) => ({
      fontWeight: '400',
      text: state.phone,
      href: 'tel:' + state.phone.replaceAll(/[\s-]/g, '')
    })
  },
  direction: {
    extend: [
      'Flex',
      'Link'
    ],
    Icon: {
      name: 'pin'
    },
    props: ({
      state
    }) => ({
      target: '_blank',
      textDecoration: 'underline',
      fontWeight: '400',
      text: 'Get Directions',
      href: `https://google.com/maps/dir//${state.lat},${state.lng}/@${state.parent.parent?.map?.lat},${state.parent.parent?.map?.lng},${state.parent.parent?.map?.zoom}z/`
    })
  }
}
