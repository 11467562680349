export const MainNav = {
  props: () => ({
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    height: '95vh',
    zIndex: '-1',
    backdropFilter: 'blur(3px)',
    color: 'white',
    flexAlign: 'center space-around',
    transition: 'D defaultBezier',
    transitionProperty: 'opacity, visibility, height',
    opacity: '0',
    visibility: 'hidden',

    '@tabletS': {
      padding: 'D1 - C',
      flexFlow: 'column'
    },

    isHome: window.location.pathname === '/',
    '.isHome': {
      background: 'brown .85 -6'
    },
    '!isHome': {
      background: 'brown .85 -26'
    },

    '.navActive': {
      height: '95vh',
      opacity: '1',
      visibility: 'visible'
    }
  }),

  childExtend: {
    extend: ['H1', 'Link'],
    props: {
      flex: 1,
      textAlign: 'center',
      fontSize: 'J',
      fontWeight: '100',
      position: 'relative',
      '@tabletS': {
        fontSize: 'H',
        flexAlign: 'center center'
      },
      ':after': {
        content: '""',
        top: '100%',
        width: '0',
        position: 'absolute',
        height: '1px',
        background: 'currentColor',
        left: '50%',
        transform: 'translate3d(-50%, 0, 1px)',
        transition: 'D defaultBezier',
        transitionProperty: 'width'
      },
      ':hover:after': {
        width: '50%'
      }
    }
  },

  Restaurants: {
    text: 'Restaurants',
    href: '/#restaurants'
  },

  Hr_1: { flex: 0, boxSize: '55% 1px', background: 'beige .35', '@tabletS': { boxSize: '1px 55%', margin: '0' } },

  About: {
    text: 'About',
    href: '/about'
  },

  Hr_2: { flex: 0, boxSize: '55% 1px', background: 'beige .35', '@tabletS': { boxSize: '1px 55%', margin: '0' } },

  Contact: {
    text: 'Contact',
    href: '/contact'
  }
}
