import { about } from './about'
import { main } from './main'
import { post } from './post'
import { restaurant } from './restaurant'
import { contact } from './contact'

export default {
  '/': main,
  '/about': about,
  '/restaurant': restaurant,
  '/post': post,
  '/contact': contact
}
