export default (FILE_URL, frameWindow = window, async = true, type = 'text/javascript') => {
  return new Promise((resolve, reject) => {
    try {
      const frameDocument = frameWindow.document || frameWindow.contentDocument
      if (!frameDocument) {
        throw new Error('Unable to access the document of the frame window.')
      }

      const scriptEle = frameDocument.createElement('script')
      scriptEle.type = type
      scriptEle.async = async
      scriptEle.src = FILE_URL

      scriptEle.addEventListener('load', (ev) => {
        resolve({
          status: true
        })
      })

      scriptEle.addEventListener('error', (ev) => {
        reject({
          status: false,
          message: `Failed to load the script ${FILE_URL}`
        })
      })

      frameDocument.body.appendChild(scriptEle)
    } catch (error) {
      reject(error)
    }
  })
}
