'use strict'

export const SocialIcons = {
  extend: ['Flex'],

  props: {
    margin: 'auto B B',
    align: 'center center',
    gap: 'B',
    '@tabletS': { margin: 'D1 - -' },
    '+ footer': { margin: '0 - -' }
  },

  childExtend: {
    extend: ['Link', 'SquareButton'],
    props: ({ key }) => ({
      icon: key,
      border: '1px, solid, transparent',
      padding: 'Y2',
      fontSize: 'B2',
      transition: 'B defaultBezier',
      transitionProperty: 'border, background, color',
      ':hover': {
        border: '1px, solid, brown .1',
        background: 'brown .05'
      }
    })
  },

  facebook: {},
  whatsapp: {},
  instagram: {},
  tiktok: {},
  email: {}
}
