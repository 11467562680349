export const NewsSection = {
  extend: [
    'Flex'
  ],
  state: 'news',
  props: {
    gap: 'C2',
    align: 'flex-start center',
    flow: 'column',
    margin: 'D - E'
  },
  heading: {
    extend: 'Flex',
    props: {
      margin: '0 auto',
      maxWidth: 'I1_default',
      textAlign: 'center',
      align: 'center flex-start',
      gap: 'A',
      flow: 'column'
    },
    H3: {
      color: 'brown',
      text: '{{ title }}',
      fontWeight: '300'
    },
    P: {
      color: 'beige',
      maxWidth: 'G2_default',
      margin: '0',
      text: '{{ description }}'
    }
  },
  Grid: {
    props: {
      columns: 'repeat(3, 1fr)',
      gap: 'B1',
      '@screenS': {
        columns: 'repeat(2, 1fr)'
      },
      '@mobileL': {
        columns: 'repeat(1, 1fr)'
      }
    },
    childExtend: 'News',
    $stateCollection: (el, s) => s.data
  }
}
