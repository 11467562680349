import { GoogleMaps } from '@symbo.ls/google-maps'
import mapStyles from './props'

export const Map = {
  extend: GoogleMaps,
  props: ({ state }) => ({
    apiKey: 'AIzaSyCVWcxbQ5vCrMnabNGPUer3sG6bSDUGF4I',
    flex: '1',
    minHeight: 'H1',
    '@tabletS': { minHeight: 'G' },
    mapOptions: {
      zoom: state.zoom || 14,
      center: {
        lat: state.lat || 41.7221993,
        lng: state.lng || 44.7585161
      },
      styles: mapStyles
    },
    markers: (state.parent.locations || []).map(({ lat, lng }) => ({ lat, lng }))
  })
}
