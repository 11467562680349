export const News = {
  extend: [
    'Flex',
  ],
  props: {
    flow: 'column',
  },
  Picture: ({
        state
      }) => ({
        src: state.poster,
        overflow: 'hidden',
        width: '100%',
        aspectRatio: '16/9'
      }),
  article: {
    props: {
      padding: 'Z2',
    },
    P: {
      props: {
        color: 'beige',
        margin: '0',
        text: 'International・2023',
      },
    },
    Strong: ({
          state
        }) => ({
          color: 'brown',
          text: state.title
        }),
  },
}