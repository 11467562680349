export const PortfolioItem = {
  extend: [
    'Flex',
    'Link'
  ],
  state: true,
  props: ({
    state
  }) => ({
    gap: 'A',
    padding: 'B2',
    align: 'flex-start center',
    textAlign: 'center',
    scrollToTop: true,
    href: state.key && '/restaurant/' + state.key,
    flow: 'column',

    round: 'B',
    transition: 'B, defaultBezier',
    transitionProperty: 'transform, box-shadow',
    boxShadow: 'transparent',
    fontWeight: '400',

    '@mobileL': {
      gap: 'Z'
    },

    ':hover': state.key && {
      transform: 'scale(1.025)',
      boxShadow: 'beige .15, 0, 5px, 36px, -6px'
    },
    ':focus': {
      transform: 'scale(1.03)'
    },
    Picture: {
      flexAlign: 'center center',
      aspectRatio: 'auto',
      margin: 'auto',
      height: 'E1',
      src: state.poster,

      '@mobileL<': {
        '& img': {
          margin: 'auto',
          boxSize: 'max-content',
          maxWidth: '57.5%',
          maxHeight: '100%'
        }
      },

      '@mobileL': {
        width: '35%',
        maxWidth: 'C3',
        margin: 'auto',
        height: 'auto',

        '& img': {
          margin: 'auto',
          boxSize: 'auto',
          width: '100%',
          maxWidth: 'none',
          maxHeight: 'none'
        }
      }
    },
    article: {
      width: '100%'
    }
  }),
  Picture: {
  },
  article: {
    Strong: null,
    P: {
      props: {
        width: '100%',
        margin: 'auto',
        '@mobileL': { margin: 'inherit' }
      },
      text: ({ state }) => state.title
    }
  }
}
