const POSTER = 'https://media.cleanshot.cloud/media/9072/BEC6ioNDbEMlGUhFVtUX14bEhsX7J1Phnk50bO6s.jpeg?Expires=1708836984&Signature=dBrvb-RWZ2R~k93IDdcC71nP3fXBKcMgZ5u2P85tkmReJNviTJWIGrD3rLMz9OeG-r~Kykm388PkArq3ntoOP7sPsY-r8k3Y2OynxyQt4c~jzeUOQrw3DphELITy829DHmNSTudFS5FZFpg8UuySFa3XJkx2fnbx9XKeu-y3BONVZZ-LPv3a5vZrK8QScNLxoDbrbavJ8Utbkw4JFv2WZKNyvcyi73dLPz5rvCkV54~PFgRG-Q7Z9bac5N10E8zVvwZ0sIQaYw0gVwy6OcU6jdKy5W-nTWmniwYwiLOC7ihCFVwRH0CDc5tmbzp2yMmBDLvFHBxoRvvHH16E7eQZxg__&Key-Pair-Id=K269JMAT9ZF4GZ'

export const Hero = {
  extend: [
    'Flex'
  ],
  props: {
    position: 'relative',
    overflow: 'hidden',
    flow: 'column',
    align: 'center center',
    background: 'brown',
    minWidth: '100%',
    height: '100%',
    heightRange: 'H 97.5%',
    margin: '0.75% 0',
    ':after, &:before': {
      content: '""',
      position: 'absolute',
      inset: '0'
    },
    ':before': {
      background: 'brown .65',
      backgroundImage: POSTER,
      backgroundSize: 'cover',
      filter: 'blur(5px)'
    },
    ':after': {
      background: 'brown .65'
    }
  },
  Video: {
    props: {
      position: 'absolute',
      boxSize: '128% 125%',
      inset: '0 0 0 0',
      autoplay: true,
      preload: 'yes',
      controls: false,
      playsinline: true,
      loop: true,
      muted: 'muted',
      // fullscreen: false,
      transform: 'translate3d(-6.5vw, 1px, 1px)',
      // poster: POSTER,
      style: {
        '-webkit-mask-image': '-webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 100%)',
        objectFit: 'cover'
      },

      '@portrait': {
        transform: 'translate3d(-5%, 1px, 1px)',
        boxSize: '38% 125%',
        margin: 'auto - 25%'
      }
    },

    mp4: {
      props: {
        src: 'https://based-files-production.based.dev/enD5U3P7x0/fi51f4bcde/c05a6236-9c03-465a-abc3-c3e6f9cebcb0-7485ddda-ae93-40af-87e8-f1e83d27d3ea-8cf60b2e-2039-48dc-9de6-4f407ecf6102.mp4',
        type: 'video/mp4'
      }
    },

    on: {
      loadedmetadata: (ev, el) => {
        const { node } = el
        const isPhone = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)
        node.playbackRate = isPhone ? 0.65 : 0.35
        node.playsinline = true
        node.muted = true
        node.play().catch(e => console.log(e))
      }
    }
  },
  VDHeader: {
    '@mobileS': {
      position: 'relative',
      margin: '- - A',
      '& img': {
        position: 'absolute',
        top: 'K',
        transform: 'translate3d(-50%, 0px, 1px)',
        left: '50%'
      }
    }
  },

  H1: {
    props: {
      margin: '12vh - auto',
      textAlign: 'center',
      lineHeight: 1.2,
      color: 'white',
      zIndex: '99',
      fontWeight: 200,
      width: '75%',
      fontSize: '6.5vmin',
      '@screenM': {
        // fontSize: 'G'
      }
    },
    Strong: { fontWeight: 600, text: 'Georgian cousine' },
    Span: {
      props: {
      },
      text: ({
        state
      }) => state.main.headline
    }
  },
  discover: {
    extend: [
      'Flex',
      'Link'
    ],
    props: {
      zIndex: '99',
      align: 'center',
      color: 'beige',
      flow: 'column',
      cursor: 'pointer',
      transition: 'B defaultBezier',
      transitionProperty: 'color, transform',
      ':hover': {
        transform: 'scale(1.015)',
        '& svg': {
          color: 'lightBeige',
          transform: 'translate3d(0, 25%, 0) scale(1.1)'
        }
      }
    },
    readStore: {
      props: {
        fontFamily: 'caligraphy',
        fontSize: '20px',
        lineHeight: '1',
        color: 'lightBeige'
      },
      text: 'Discover More'
    },
    since: {
      text: 'Since 1998'
    },
    Icon: {
      transition: 'B defaultBezier',
      transitionProperty: 'color, transform',
      margin: 'B - A2',
      name: 'arrowDown'
    },
    on: {
      click: (el) => {
        const rootNode = document.body
        rootNode.scrollTo({
          top: window.innerHeight,
          behavior: 'smooth'
        })
      }
    }
  }
}
