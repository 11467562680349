import { floatGallerySliding, registerFrameListener } from '../snippets'

export const GalleryItem = {
  extend: [
    'Flex'
  ],
  props: {
    align: 'center center'
  },
  state: true,
  Img: {
    state: 'poster',
    props: ({
      state
    }) => {
      const randomX = (0.5 - Math.random()) * 25
      const randomY = (0.5 - Math.random()) * 25
      return {
        transform: `translate3d(${randomX}%, ${randomY}%, 1px)`,
        src: state.value,
        maxWidth: '65%'
      }
    },
    on: {
      render: el => registerFrameListener(el),
      frame: el => floatGallerySliding(el)
    }
  }
}
