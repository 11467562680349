export const VDHeader = {
  extend: [
    'Flex'
  ],
  props: {
    position: 'sticky',
    top: '0',
    zIndex: '100',
    padding: 'A C',
    align: 'center space-between',
    minWidth: '100%',
    width: '100%',
    userSelect: 'none',
    '@mobileL': {
      // gap: 'C1',
      // flow: 'column'
    }
  },
  languages: {
    extend: 'Flex',
    props: {
      flex: '0 1 15%',
      gap: 'B2'
    },
    childExtend: {
      extend: 'Link',
      props: ({ key, state }) => ({
        isMain: window.location.pathname === '/',
        active: state.__root.lang === key,
        color: 'beige',
        '!isMain': {
          '.active': {
            display: 'none'
          }
        }
      })
    },
    ge: {
      text: 'ქარ'
    },
    en: {
      text: 'Eng'
    }
  },
  Link: {
    props: {
      href: '/'
    },
    Img: {
      src: 'https://based-files-production.based.dev/enD5U3P7x0/fi0f52b279/57deea5a-1c36-44a6-9cdd-df2ca7267bf3-7e6e430f-d2f7-4db8-a37e-29d9ef11d8d5-49582f89-0ab6-44e4-8f32-c2aa591e01fd.png',
      height: '42px'
    }
  },
  Span: {
    props: {
      flex: '0 1 15%'
    },
    Menu: {
      margin: '- - - auto',
      onClick: (ev, el, s) => {
        s.toggle('navActive')
      }
    }
  },

  MainNav: {}
}
