import { Loader } from '@googlemaps/js-api-loader'

export const initGoogleMaps = async (el, s) => {
  const {
    data,
    props
  } = el
  const {
    version,
    apiKey,
    mapOptions
  } = props
  if (data.loader) return

  const loader = new Loader({
    apiKey: apiKey || '{{ GOOGLE_MAPS_API_KEY }}',
    version: version || 'weekly'
  })

  loader
    .importLibrary('maps')
    .then(({ Map }) => {
      const map = new Map(el.node, mapOptions)

      if (props.markers) {
        props.markers.forEach(({
          lat,
          lng,
          title
        }, key) => {
          const marker = new google.maps.Marker({
            position: {
              lat,
              lng
            },
            map,
            title
          })

          marker.addListener('click', function () {
            s.update({
              activeMarker: key
            })
          })
        })
      }
    })
}
