export const main = {
  Hero: {
  },
  Flex: {
    props: {
      flow: 'column',
      maxWidth: 'K_default',
      gap: 'C1',
      margin: '0 auto',
      '@mobileM': {
        maxWidth: 'none'
      }
    },
    Portfolio: {
      props: { id: 'restaurants' },
      state: 'portfolio'
    },
    NewsSection: {
    }
  },

  Footer: {}
}
