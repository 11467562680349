export const MapIframe = {
  extend: [
    'Iframe'
  ],
  props: (el, s) => ({
    background: 'white .05',
    src: `https://www.google.com/maps/embed?ll=${s.lat},${s.lng}`,
    allowfullscreen: '',
    loading: 'lazy',
    referrerpolicy: 'no-referrer-when-downgrade',
    round: 'A',
    flex: '1',
    minWidth: '0',
    border: '0'
  })
}
