export const post = {
  props: {
    flexFlow: 'column',
    maxWidth: '100vw',
    boxSize: '100%'
  },

  VDHeader: {
    background: 'black',
    margin: '0.75% 0 0'
  },

  Editorjs: {
    state: 'post',
    $editorjs: ({
      state
    }) => state.post
  },

  Footer: {}
}
