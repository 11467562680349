export const RestaurantNav = {
  extend: [
    'Flex'
  ],
  props: {
    align: 'center',
    margin: 'B2 - C2',
    columns: '3',
    columnGap: 'D',
    columnRule: '1px, solid, #E4E4E4'
  },
  childExtend: {
    extend: 'Link',
    props: {
      fontWeight: '400',
      cursor: 'pointer',
      ':hover': {
        textDecoration: 'underline'
      },
      onClick: (ev, el, s) => {
        const element = document.getElementById(el.key)
        const headerOffset = el.key === 'menu' ? 95 : 0
        const elementPosition = element.getBoundingClientRect().top
        const offsetPosition = elementPosition + window.scrollY - headerOffset

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        })
      }
    }
  },
  menu: {
    if: (el, s) => s.menu,
    props: {
      text: 'Menu'
    }
  },
  gallery: {
    if: (el, s) => s.gallery,
    props: {
      text: 'Gallery'
    }
  },
  locations: {
    if: (el, s) => s.map,
    props: {
      text: 'Locations'
    }
  }
}
