export const Portfolio = {
  extend: [
    'Flex'
  ],
  props: {
    margin: 'D1 0',
    gap: 'B1',
    '@tabletS': {
      width: '100%',
      flow: 'column'
    }
  },

  VerticalTitle: {
  },

  Grid: {
    state: 'data',
    props: {
      columns: 'repeat(5, 1fr)',
      flex: 1,
      '@tabletS': {
        // margin: '- -B2'
      },
      '@tabletL': {
        columns: 'repeat(3, 1fr)'
      },
      '@mobileL': {
        columns: 'repeat(2, 1fr)',
        gap: 'A 0'
      },
      '@mobileXS': {
        columns: 'repeat(1, 1fr)',
        gap: 'A'
      }
    },
    childExtend: 'PortfolioItem',
    $propsCollection: ({ state }) => state
  }
}
