import { PageFlip } from 'page-flip'
import { deepClone } from 'smbls'
import { registerFrameListener } from '../snippets'

export const PaperMenu = {
  extend: [],

  if: (el, s) => s.parse().length,

  props: {
    width: '100%',
    padding: 'D D1',
    backgroundImage: 'https://media.cleanshot.cloud/media/9072/MVeD7pFr3le8UsdWZn3pykLMAhUgc2HRUCJX511a.jpeg?Expires=1708840982&Signature=eby0q8zahY~LQWKnhkat6AEVxzwGCo7WiIdAObu3mnoQ0E-8aEZMR3l4GhHVcq2eXIpsJWL7N6neeqdLt4bPErvuU3B87ThRLiSNZRE3qIiC3ZR8j4pYU3NxwXTWJtcnwbuNYRv7Un1Q7lg002QFn9OXGpZQYJQLuKNJMdOsk0UMfgTTW-Z5B2jVKPI7-XDyfv28XlPUWgtHQQLAtDhmp-2HoYzp~6BxXEfwTdn7f~vRvLKMmkwcmp0UyukWkUExwuat~qjNEBcTPP1zu4C1BzrwllGmhDr5V~y5z9RHaZMHqmAD~W8OBoLjBPvBMmd33pkTzM3LFKr4zxYoKxEFDg__&Key-Pair-Id=K269JMAT9ZF4GZ',
    backgroundSize: 'cover'
  },

  Pages: {
    data: {},

    props: {
      style: { maxWidth: '100vmin !important' },
      margin: 'auto',
      width: '65%'
    },

    childExtend: {
      props: {
        class: 'page',
        aspectRatio: '842 / 1190',
        fontSize: '0'
      },
      Img: ({ state }) => ({ display: 'block', boxSize: '100%', src: state.poster })
    },

    $stateCollection: (el, s) => {
      return deepClone(s.parse())
    },

    on: {
      frame: (el, s) => {
        if (s.opened) return

        const parentTop = el.node.getBoundingClientRect().top
        const windowHeight = window.innerHeight

        if (parentTop < windowHeight / 2) {
          el.data.pageFlip.flipNext()
          s.opened = true
        }
      },
      render: (el, s) => {
        registerFrameListener(el)

        if (s.loaded) return

        const acceleration = 1.35
        const ratio = 1190 / 842
        const width = 842 * acceleration
        const height = width * ratio

        const isSmallScreen = width > window.innerWidth
        const scaleWidth = isSmallScreen ? window.innerWidth - 32 : width
        const scaleHeight = isSmallScreen ? scaleWidth * ratio : height

        const pageFlip = el.data.pageFlip = new PageFlip(el.node, {
          width: scaleWidth,
          height: scaleHeight,

          size: 'stretch',
          minWidth: 315,
          minHeight: 420,
          maxHeight: 1350,

          maxShadowOpacity: 0.5, // Half shadow intensity
          showCover: true,
          mobileScrollSupport: true // disable content scrolling on mobile devices
        })

        pageFlip.loadFromHTML(el.node.querySelectorAll('.page'))

        s.loaded = true
      }
    }
  }
}
