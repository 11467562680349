export const LocationsList = {
  extend: [
    'Flex'
  ],
  props: {
    flow: 'column',
    minWidth: 'H',
    gap: 'C',
    '@tabletS': {
      flow: 'row',
      padding: '- B1',
      overflow: 'auto'
    }
  },
  childExtend: 'Location',
  $propsCollection: ({
    state
  }) => Object.keys(state.parse())
}
