export const VerticalTitle = {
  extend: [
    'Pseudo'
  ],
  props: {
    position: 'relative',

    ':before': {
      content: '""',
      position: 'absolute',
      left: 'Z',
      border: 'lightBeige, solid',
      '@tabletS<': {
        borderWidth: '0 0 0 1px',
        top: '35%',
        bottom: 'C1'
      },
      '@tabletS': {
        borderWidth: '0 0 1px 0',
        left: '20%',
        right: '20%',
        top: 'A'
      }
    }
  },
  H6: ({
    state
  }) => ({
    text: state.title,
    color: 'beige +85',
    position: 'relative',
    zIndex: 2,
    '@tabletS<': {
      margin: '0 C',
      transform: 'scale(-1, -1)',
      writingMode: 'tb'
    },
    '@tabletS': {
      margin: '0 auto',
      padding: '0 A',
      background: 'white',
      textAlign: 'center',
      width: 'max-content'
    }
  })
}
