export const Gallery = {
  extend: [
    'Grid'
  ],
  if: (el, s) => s.length,
  props: {
    columns: 'repeat(3, minmax(300px, 33%))',
    padding: 'G - F',
    gap: 'D C',
    '@mobileL': {
      columns: 'repeat(1, 1fr)',
      gap: 'B'
    }
  },
  childExtend: 'GalleryItem',
  $stateCollection: ({ state, props }) => props.imgLimit ? state.filter((v, i) => i < props.imgLimit) : state
}
