export default async (el, s) => {
  // The location of Uluru
  const position = {
    lat: -25.344,
    lng: 131.031
  }
  // Request needed libraries.
  // @ts-ignore
  const {
    Map
  } = await google.maps.importLibrary('maps')
  const {
    AdvancedMarkerElement
  } = await google.maps.importLibrary('marker')

  // The map, centered at Uluru
  map = new Map(el.node, {
    zoom: 4,
    center: position,
    mapId: 'DEMO_MAP_ID'
  })
}
