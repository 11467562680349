export default {
  main: {
    headline: ' and International flavors for embracing culinary evolution'
  },
  portfolio: {
    title: 'Portfolio',
    data: [
      {
        key: 'hachapuri',
        poster: 'https://p194.p3.n0.cdn.getcloudapp.com/items/v1uPjrGg/9cf26e24-3587-4b9a-bc7e-3338cafc1b9e.png?v=6f8a878eb267e28b88832ad282272098',
        title: 'Hachapuri',
        headline: 'Georgian gastro-adventure in Budapest',
        description: 'Georgian gastro-adventure in Budapest',
        paragraph: '"Georgian food has an international recognition and that\'s why we are proud of it. Georgian cuisine is based on our rich culture and traditions.'
      },
      {
        key: 'fiorino',
        poster: 'https://p194.p3.n0.cdn.getcloudapp.com/items/p9ueNK4Q/615ef9f8-be5c-4fea-aafd-f3d242a59522.png?v=f5dd5c88b302758e74896c1798ce7417',
        title: 'Fiorino',
        headline: 'Authentic Italian cuisine in Tbilisi',
        description: 'Authentic Italian cuisine in Tbilisi',
        paragraph: '"Georgian food has an international recognition and that\'s why we are proud of it. Georgian cuisine is based on our rich culture and traditions.'
      },
      {
        key: 'chop-sticks',
        poster: 'https://p194.p3.n0.cdn.getcloudapp.com/items/xQuRjg8W/0851861e-820d-4ca8-bfc2-168d8d1e4ed6.png?v=d326b161a7fb8a031b3f053344c09aaa',
        title: 'Chop Sticks',
        headline: 'Authentic Italian cuisine in Tbilisi',
        description: 'Authentic Italian cuisine in Tbilisi',
        paragraph: '"Georgian food has an international recognition and that\'s why we are proud of it. Georgian cuisine is based on our rich culture and traditions.'
      },
      {
        key: 'billy-burke-s-irish-pub',
        poster: 'https://p194.p3.n0.cdn.getcloudapp.com/items/o0uY7D10/1064e8df-42dc-44f7-866f-f202ea7bc0c2.png?v=a7396c68da0e16ea88affa461ad9d1fe',
        title: 'Billy Burke\'s Irish Pub',
        headline: 'Authentic Italian cuisine in Tbilisi',
        description: 'Authentic Italian cuisine in Tbilisi',
        paragraph: '"Georgian food has an international recognition and that\'s why we are proud of it. Georgian cuisine is based on our rich culture and traditions.'
      },
      {
        key: '4concept',
        poster: 'https://p194.p3.n0.cdn.getcloudapp.com/items/jkuAEKGG/5971d6a4-4428-48ea-8718-1fa146ec3cb1.png?v=4193e1023b8fd03097d3a0681912c8ab',
        title: '4concept',
        headline: 'Authentic Italian cuisine in Tbilisi',
        description: 'Authentic Italian cuisine in Tbilisi',
        paragraph: '"Georgian food has an international recognition and that\'s why we are proud of it. Georgian cuisine is based on our rich culture and traditions.'
      },
      {
        key: 'hinkali-factory',
        poster: 'https://p194.p3.n0.cdn.getcloudapp.com/items/L1uDegA0/33274b46-b94f-48bf-b2f4-40cdef874901.png?v=453b8bd7df0463910a64ea69d8b7f968',
        title: 'Hinkali Factory',
        headline: 'Georgian gastro-adventure in Budapest',
        description: 'Georgian gastro-adventure in Budapest',
        paragraph: '"Georgian food has an international recognition and that\'s why we are proud of it. Georgian cuisine is based on our rich culture and traditions.',
        gallery: [
          {
            poster: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.E57NldS8zPJVXft8g_vm7AHaE8%26pid%3DApi&f=1&ipt=de6b43b942ff9deefe094c8f906af89aa45869bbe7fa54e01d0f2d32cfb22a2a&ipo=images'
          },
          {
            poster: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse1.mm.bing.net%2Fth%3Fid%3DOIP.TQh8j8mKFt1jBIU2VJCNdwHaE8%26pid%3DApi&f=1&ipt=4cdf262006009cb12c2f97ad3c741f3d76ad68cdb4db8a2eca425f664469eacf&ipo=images'
          },
          {
            poster: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse2.mm.bing.net%2Fth%3Fid%3DOIP.Y7OUZhdvMizOWyf6UQ7oJQHaE5%26pid%3DApi&f=1&ipt=a12b6da8f4348b47581c6bea408fa5705143d53f01b57eb683ddc98043a60b23&ipo=images'
          },
          {
            poster: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse4.mm.bing.net%2Fth%3Fid%3DOIP.zqp650XrFDOV7lTA9utTGAHaE8%26pid%3DApi&f=1&ipt=9e7cf94468b7321a40bc075a6877cb5886159939dbc2b8eed37c08b1f305cb0e&ipo=images'
          },
          {
            poster: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse3.mm.bing.net%2Fth%3Fid%3DOIP.Lg-_dDFJj2dwgjpIfdMeiwHaE6%26pid%3DApi&f=1&ipt=6d5836d5b807bc34c8e739b67bf106e8bd354f099dd5e009511976f2b9c70e54&ipo=images'
          },
          {
            poster: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse4.explicit.bing.net%2Fth%3Fid%3DOIP.voHYpUwKTX5rmD9yecPBsQHaHa%26pid%3DApi&f=1&ipt=ba159f764fa6e83c3b2ba73eb59a336ac03de8a81b4ac498f2944d6aab8a9c3e&ipo=images'
          },
          {
            poster: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse4.mm.bing.net%2Fth%3Fid%3DOIP.2HfPnf6exP2drjJj80luNgHaE8%26pid%3DApi&f=1&ipt=95bf91957429bdc06c102072ff2a0576384c4472c046a1f4a497cb64570f1395&ipo=images'
          },
          {
            poster: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse1.mm.bing.net%2Fth%3Fid%3DOIP.jW3Djf-NvkdvlKEJD9KlxAHaFj%26pid%3DApi&f=1&ipt=a23864f346387bd6aaca10f38b8848b8d69c87bec3abb6378d53a95f7e3b3095&ipo=images'
          },
          {
            poster: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse3.mm.bing.net%2Fth%3Fid%3DOIP.D0gVH7_oCAhsCAVBml4dJQHaE5%26pid%3DApi&f=1&ipt=b2ac244f470a2b2fa3fef86902036bfae17a382d543395c4e37283b202dab242&ipo=images'
          },
          {
            poster: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse4.mm.bing.net%2Fth%3Fid%3DOIP.zqp650XrFDOV7lTA9utTGAHaE8%26pid%3DApi&f=1&ipt=9e7cf94468b7321a40bc075a6877cb5886159939dbc2b8eed37c08b1f305cb0e&ipo=images'
          },
          {
            poster: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse3.mm.bing.net%2Fth%3Fid%3DOIP.Lg-_dDFJj2dwgjpIfdMeiwHaE6%26pid%3DApi&f=1&ipt=6d5836d5b807bc34c8e739b67bf106e8bd354f099dd5e009511976f2b9c70e54&ipo=images'
          },
          {
            poster: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse4.explicit.bing.net%2Fth%3Fid%3DOIP.voHYpUwKTX5rmD9yecPBsQHaHa%26pid%3DApi&f=1&ipt=ba159f764fa6e83c3b2ba73eb59a336ac03de8a81b4ac498f2944d6aab8a9c3e&ipo=images'
          },
          {
            poster: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse4.mm.bing.net%2Fth%3Fid%3DOIP.2HfPnf6exP2drjJj80luNgHaE8%26pid%3DApi&f=1&ipt=95bf91957429bdc06c102072ff2a0576384c4472c046a1f4a497cb64570f1395&ipo=images'
          },
          {
            poster: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse1.mm.bing.net%2Fth%3Fid%3DOIP.jW3Djf-NvkdvlKEJD9KlxAHaFj%26pid%3DApi&f=1&ipt=a23864f346387bd6aaca10f38b8848b8d69c87bec3abb6378d53a95f7e3b3095&ipo=images'
          },
          {
            poster: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse3.mm.bing.net%2Fth%3Fid%3DOIP.D0gVH7_oCAhsCAVBml4dJQHaE5%26pid%3DApi&f=1&ipt=b2ac244f470a2b2fa3fef86902036bfae17a382d543395c4e37283b202dab242&ipo=images'
          }
        ],
        menu: [
          {
            poster: 'https://based-files-production.based.dev/enD5U3P7x0/ficb5b1f81/5a045e74-74ed-4774-be5e-7533b6e04536-5888a07c-5bae-42fc-918a-f69a82de8c16-40c27ede-ee33-4e20-b2df-df0b3da15e2b.jpeg'
          },
          {
            poster: 'https://based-files-production.based.dev/enD5U3P7x0/fia34efcb2/e8a790be-ede7-47b6-904c-fb8e5fa4191b-62a31977-fac0-4e91-afd8-338a5d986341-b2a461a7-b070-49e5-8ef2-5e3d595179b9.jpeg'
          },
          {
            poster: 'https://based-files-production.based.dev/enD5U3P7x0/fiadcec15e/5cdba2e3-1ead-4110-b0e1-363cdb682251-ad98a621-d27e-4814-8987-a7e0ac74beb7-2f44372f-a965-47a9-83cf-8690f11dcc07.jpeg'
          },
          {
            poster: 'https://based-files-production.based.dev/enD5U3P7x0/fi5ac5fbc1/df271c64-444c-4d04-b63b-228bfee8ac8a-3a5ee285-8d73-4bcf-97e2-0f80f400c8db-197a3b9c-7ef4-4707-843e-999e3068b34b.jpeg'
          },
          {
            poster: 'https://based-files-production.based.dev/enD5U3P7x0/fif8d0ca49/dcaa2e33-ebaf-45a7-aac9-789e4288cf71-95f40be7-21d6-4462-b486-8f217f33883d-b7e2d135-20a7-4d57-9dfe-128d37b4c596.jpeg'
          },
          {
            poster: 'https://based-files-production.based.dev/enD5U3P7x0/fie21e701f/35e528e8-9676-4dbd-b7ee-8b508ba38e87-84e1f589-49e1-4ed6-a7cc-a2fd7349c6f1-f719e4cb-6b3e-40d0-a3dc-43e01d5b2f9f.jpeg'
          }
        ],
        map: {
          zoom: 14,
          lat: 41.7221993,
          lng: 44.7585161
        },
        locations: [
          {
            address: '11, Ilo Mosashvili street, Tbilisi, Georgia',
            workStart: '11:00',
            workEnd: '23:30',
            phone: '+995 577 33-03-40',
            lat: 41.7106883,
            lng: 44.7525731
          },
          {
            address: '77, Kostava street, Tbilisi, Georgia',
            workStart: '11:00',
            workEnd: '23:30',
            phone: '+995 577 33-03-40',
            lat: 41.7229388,
            lng: 44.7771492
          },
          {
            address: '2, Phkhovi street, Tbilisi, Georgia',
            workStart: '11:00',
            workEnd: '23:30',
            phone: '+995 577 33-03-40',
            lat: 41.6988403,
            lng: 44.8019235
          }
        ],
        social: {
          facebook: '#facebook',
          tiktok: '#tiktok',
          instagram: '#instagram',
          whatsapp: '#whatsapp',
          mail: '#mail'
        }
      },
      {
        key: 'machakhela-samikitno',
        poster: 'https://p194.p3.n0.cdn.getcloudapp.com/items/mXuDPqL8/5b96173f-8fc0-46e2-9212-523a972b0427.png?v=315c2ee081345d8f53935a0531f76bc7',
        title: 'Machakhela - Samikitno',
        headline: 'Authentic Italian cuisine in Tbilisi',
        description: 'Authentic Italian cuisine in Tbilisi',
        paragraph: '"Georgian food has an international recognition and that\'s why we are proud of it. Georgian cuisine is based on our rich culture and traditions.'
      },
      {
        key: 'taghlaura',
        poster: 'https://p194.p3.n0.cdn.getcloudapp.com/items/P8u28R4d/0cfea0e6-dc13-4575-b74e-dbea969871cc.png?v=f010d90483e788e922ed508da38f17e4',
        title: 'Taghlaura',
        headline: 'Asian fusion restaurant located by Vake Park',
        description: 'Asian fusion restaurant located by Vake Park',
        paragraph: '"Georgian food has an international recognition and that\'s why we are proud of it. Georgian cuisine is based on our rich culture and traditions.'
      },
      {
        poster: 'https://based-files-production.based.dev/enD5U3P7x0/fi45e685f7/59c23cb8-ff7c-4a90-a3bd-98fcffe30add-8025a44b-fe4b-4ee4-b25d-46888f093a7a-50995f93-d26f-4c28-b16f-0bce1d4078d6.png',
        title: 'Coming soon'
      }
    ]
  },
  news: {
    title: 'Global Fusion: Georgian Cuisine Embraces Overseas Flavors in Culinary Evolution',
    description: 'Georgian food has an international recognition and that\'s why we are proud of it. Georgian cuisine is based on our rich culture and traditions.',
    data: [
      {
        poster: 'https://p194.p3.n0.cdn.getcloudapp.com/items/eDurgxW2/6924c621-7f98-4d2a-b2de-e36bd07b426c.png?v=547e00d678d0c080f687a21bc80753bc',
        title: 'Samikitno receives international "Georgian food has an international recognition',
        category: 'International',
        year: '2023'
      },
      {
        poster: 'https://p194.p3.n0.cdn.getcloudapp.com/items/E0uXKq51/4468c204-596d-4f48-b9f8-fa7f0d666946.png?v=dbd2067ece02647c687a96a0f71e976e',
        title: 'Samikitno receives international "Georgian food has an international recognition',
        category: 'International',
        year: '2023'
      },
      {
        poster: 'https://p194.p3.n0.cdn.getcloudapp.com/items/YEuePAgL/cae40a91-3811-454a-8801-495441278c63.png?v=88543c1a7dbf5cc560f6cedf7a4d7953',
        title: 'Samikitno receives international "Georgian food has an international recognition',
        category: 'International',
        year: '2023'
      }
    ]
  },
  lang: 'en',
  route: '/state',
  routeInContent: '/',
  blog: {
    title: 'Blog',
    data: [
      {
        title: 'New Post 0',
        key: 'new-post-0',
        date: '2023-12-14T17:44:21.254Z',
        author: 'Nika',
        content: {
          time: 1702575889652,
          blocks: [
            {
              id: 's7nkiE-4PG',
              type: 'header',
              data: {
                text: 'asdsadsa',
                level: 2,
                alignment: 'left'
              }
            },
            {
              id: 'lsuRcj1bWh',
              type: 'paragraph',
              data: {
                text: 'asdadsa'
              }
            },
            {
              id: 'aRF3TjFOeC',
              type: 'domqlCode',
              data: {
                code: ''
              }
            }
          ],
          version: '2.26.5'
        },
        index: 0
      }
    ],
    key: 'blog'
  }
}
