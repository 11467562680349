
export const contact = {
  props: {
    flexFlow: 'column',
    maxWidth: '100vw',
    boxSize: '100%'
  },

  VDHeader: {
    background: 'black',
    margin: '0.75% 0 0'
  },

  Box: {
    tag: 'article',

    props: {
      width: '100%',
      margin: '0 auto',
      maxWidth: 'J',
      padding: 'D1 B -'
    },

    H1: {
      fontWeight: '400',
      text: 'Contact'
    },

    P: {
      maxWidth: '48%',
      color: 'beige',
      margin: 'X - B1',
      text: 'We celebrate the culinary heart of Georgia and the world beyond.'
    }
  },

  Box_2: {
    tag: 'article',

    props: {
      width: '100%',
      margin: '0 auto',
      maxWidth: 'J',
      padding: 'B B D1'
    },

    Flex: {
      props: {
        color: 'beige',
        gap: 'C1',
        '@mobileL': {
          flow: 'y'
        }
      },

      childExtend: 'P',

      Form: {
        props: {
          flexFlow: 'column',
          flexAlign: 'start start',
          flex: 1,
          gap: 'A',
          childProps: {
            padding: 'Z2 A2',
            required: true,
            round: 'Z'
          },

          onSubmit: (ev, el) => {
            ev.preventDefault()
          }
        },

        'Input.name': {
          theme: 'dialog',
          placeholder: 'Name'
        },

        'Input.email': {
          theme: 'dialog',
          placeholder: 'Email'
        },

        'Input.phone': {
          theme: 'dialog',
          placeholder: 'Phone'
        },

        'Textarea.contact': {
          theme: 'dialog',
          placeholder: 'Message'
        },

        Button: {
          theme: null,
          background: 'black',
          color: 'beige',
          type: 'submit',
          text: 'Send'
        }
      },

      Location: {
        props: {
          flex: 1
        },
        state: 'portfolio/data/5/locations/1'
      }
    }
  },

  Map: {
    state: 'portfolio/data/5/map'
  },

  Portfolio: {
    props: { id: 'restaurants' },
    state: 'portfolio'
  },

  Footer: {}
}
