export default async (el, s) => {
  const globalObject = {
    apiKey: el.props.apiKey,
    v: 'weekly',
    p: 'The Google Maps JavaScript API',
    c: 'google',
    l: 'importLibrary',
    q: '__ib__'
  }

  let promise
  let scriptElement
  let queryParams
  const loadedLibraries = new Set()
  const mapAPI = globalObject.google?.maps || (globalObject.google = {}).maps || {}

  const loadMapAPI = () => {
    return promise || (promise = new Promise(async (resolve, reject) => {
      await (scriptElement = document.createElement('script'))
      queryParams.set('libraries', [...loadedLibraries].join(''))

      for (const key in globalObject) {
        queryParams.set(key.replace(/[A-Z]/g, match => '_' + match[0].toLowerCase()), globalObject[key])
      }

      queryParams.set('callback', 'Function.prototype')
      scriptElement.src = `https://maps.${globalObject.c}apis.com/maps/api/js?` + queryParams
      mapAPI[globalObject.q] = resolve

      scriptElement.onerror = () => promise = reject(Error(globalObject.p + ' could not load.'))
      scriptElement.nonce = document.querySelector('script[nonce]')?.nonce || ''
      document.head.appendChild(scriptElement)

      await el.context.snippets.initNY(el, s)
    }))
  }

  mapAPI[globalObject.l]
    ? console.warn(globalObject.p + ' only loads once. Ignoring:', globalObject)
    : mapAPI[globalObject.l] = (library, ...args) =>
      loadedLibraries.add(library) && loadMapAPI().then(() => mapAPI[globalObject.l](library, ...args))
}
