export const LocationsSection = {
  extend: [
    'Flex'
  ],
  if: ({
    state
  }) => state.locations,
  props: {
    gap: 'C1',
    width: '100%',
    maxWidth: 'K',
    margin: 'F2 auto',
    '@tabletS': {
      gap: 'B2',
      flow: 'column'
    }
  },
  VerticalTitle: {
    ':before': {
      '@tabletS<': {
        top: '20%',
        left: '0.85em',
        bottom: '-A'
      }
    },
    H6: {
      text: 'Locations',
      '@tabletS<': {
        margin: '0 - -'
      }
    }
  },
  LocationsList: {
    state: 'locations',
    props: {
      '@tabletS': {
        order: 2
      }
    }
  },
  Map: {
    state: 'map'
  }
}
