
export const Footer = {
  props: {
    flexFlow: 'row wrap',
    margin: 'auto - -',
    padding: 'D1 B2 A1',
    fontSize: 'Z',
    background: 'black',
    color: 'white',
    flexAlign: 'center space-between',
    textAlign: 'center'
  },

  Nav: {
    props: {
      flexFlow: 'row',
      flex: 3,
      gap: 'B',
      childProps: {
        fontWeight: '400',
        ':hover': {
          textDecoration: 'underline'
        }
      }
    },

    childExtend: 'Link',

    $propsCollection: [{
      text: 'Main',
      href: '/'
    }, {
      text: 'About',
      href: '/about'
    }, {
      text: 'Contact',
      href: '/contact'
    }]
  },

  Text: {
    flex: 2,
    color: 'beige',
    text: 'VDCapital © 2023',
    '@mobileL': {
      margin: 'G1 - -',
      order: 3,
      minWidth: '100%'
    }
  },

  Privacy: {
    props: {
      flexAlign: 'center end',
      flex: 3,
      gap: 'B',
      childProps: {
        fontWeight: '400',
        ':hover': {
          textDecoration: 'underline'
        }
      }
    },

    childExtend: 'Link',

    $propsCollection: [{
      text: 'Terms',
      href: '/'
    }]
  }
}
