import { initAnimationListener } from '../snippets'

export const about = {
  props: {
    flexFlow: 'column',
    maxWidth: '100vw',
    boxSize: '100%'
  },

  VDHeader: {
    background: 'black',
    margin: '0.75% 0 0'
  },

  Box: {
    tag: 'article',

    props: {
      width: '100%',
      margin: '0 auto',
      maxWidth: 'J',
      padding: 'D1 B -'
    },

    H1: {
      fontWeight: '400',
      text: 'About Us'
    },

    P: {
      maxWidth: '48%',
      '@mobileL': {
        maxWidth: '100%'
      },
      color: 'beige',
      margin: 'X - B1',
      text: 'We celebrate the culinary heart of Georgia and the world beyond. Our collection of restaurants is a testament to the power of food to delight, inspire, and bring people together. From beloved Georgian dishes to curated international experiences, we create dining destinations in Tbilisi that nourish the soul.'
    }
  },

  Gallery: {
    props: { imgLimit: 3, padding: 'E -' },
    state: 'portfolio/data/5/gallery',
    on: {
      init: (el) => initAnimationListener(el)
    }
  },

  Box_2: {
    tag: 'article',

    props: {
      width: '100%',
      margin: '0 auto',
      maxWidth: 'J',
      padding: 'D1 B'
    },

    H4: {
      fontWeight: '400',
      text: 'Emphasis on  Georgian Heritage'
    },

    Flex: {
      props: {
        color: 'beige',
        gap: 'C1',
        '@mobileL': {
          gap: '0',
          flow: 'y'
        }
      },

      childExtend: 'P',

      0: {
        color: 'beige',
        margin: 'X - B1',
        text: 'Choose a company name that resonates with your vision (flavor-focused, regional, adventurous, etc.)'
      },
      1: {
        color: 'beige',
        margin: 'X - B1',
        text: 'Pair your "About Us" with high-quality images of your restaurants and delicious dishes.'
      }
    }
  },

  Map: {
    state: 'portfolio/data/5/map'
  },

  Box_3: {
    tag: 'article',

    props: {
      width: '100%',
      margin: '0 auto',
      maxWidth: 'J',
      padding: 'D1 B'
    },

    H4: {
      fontWeight: '400',
      text: 'Address'
    },

    Flex: {
      props: { color: 'beige', gap: 'C1', margin: 'B2 - -' },

      Location: {
        state: 'portfolio/data/5/locations/1'
      }
    }
  },

  Footer: {}
}
